@mixin displayColumn {
  display: flex;
  flex-direction: column;
}
@mixin displayRow {
  display: flex;
}
@mixin displayRowCenter {
  @include displayRow;
  justify-content: center;
  align-self: center;
}
@mixin displayColumnCenter {
  @include displayColumn;
  align-items: center;
  justify-content: center;
}
