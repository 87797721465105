.landing-page-expired {
  .landingpage__innerdiv__content,
  .landingpage__innerdiv {
    height: auto;
  }
  .container {
    padding-bottom: 70px;
  }

  .btn-submit {
    color: #1b5ac3;
  }
  .form-control {
    background-color: transparent !important;
    caret-color: #fff;
    border: 3px solid #fff;
    color: #fff;
  }
}

.contentEmailBox form {
  display: flex;
  flex-direction: column;
}

.contentEmailBox form button {
  background: #fff;
  border-radius: 5px;
  margin-top: 19px;
  width: 110px;
  margin: 20px auto;
}

.contentEmailBox form input {
  width: 340px;
  height: 41px;
  padding: 0 10px;
  background: transparent;
  color: #fff;
  box-shadow: none;
  outline: none;
}

.contentEmailBox form input::placeholder {
  color: #fff;
}

.contentEmailBox form input:focus {
  outline: none;
}

.contentEmailBox {
  position: relative;
  width: 500px;
  margin: 0 auto;
  height: 300px;
  border-radius: 30px;
  background: #1d65d6 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 20px #0000001a;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
  color: #fff;
}

.contentEmailBox h3 {
  color: #fff;
}
