@import './styles/variables.scss';

@font-face {
  font-family: 'ArchivoNarrow-Regular';
  src: local('ArchivoNarrow-Regular'), url(./assets/fonts/ArchivoNarrow-Regular.ttf);
}

@font-face {
  font-family: 'Oswald-Regular';
  src: local('Oswald-Regular'), url(./assets/fonts/Oswald.ttf);
}
@font-face {
  font-family: 'ArchivoNarrow-Bold';
  src: local('ArchivoNarrow-Bold'), url(./assets/fonts/ArchivoNarrow-Bold.ttf);
}

body {
  height: 100vh;
  margin: 0;
  font-family: 'ArchivoNarrow-Regular', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent;
}
h1 {
  font-family: 'Oswald-Regular';
}
#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app-root {
  left: $sidebar-width;
  position: fixed;
}

.btn-outline-primary {
  background-color: transparent;
  color: $primary-color;
  border: none;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:disabled {
  background-color: transparent;
  color: #d4402e;
}

.btn-outline-primary :disabled {
  background-color: transparent;
  color: #d4402e;
  padding-bottom: 0.375rem;
}
.btn-primary,
.btn-danger {
  background-color: $primary-color;
  border: none;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:disabled,
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:disabled {
  background-color: #d4402e;
}

.dropdown-tools .btn-primary:hover,
.dropdown-tools .btn-primary:focus,
.dropdown-tools .btn-primary:disabled {
  background-color: $dark-grey !important;
}

.btn-primary:disabled {
  background-color: #d4402e;
  padding-bottom: 0.375rem;
}

.btn-link,
.btn-link:hover,
.btn-link:focus {
  color: $primary-color;
}

.primary-bg {
  background-color: $pannel-bg-color;
}

.ae-ui .ae-toolbar,
.ae-ui [class^='ae-toolbar-'] {
  background-color: $primary-color;
}

.custom-shadow {
  box-shadow: $left-box-shadow;
}

td {
  vertical-align: middle !important;
}
.text-danger {
  color: #de5348;
}
a {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
.modal-body {
  padding: 0px;
  margin: 1rem !important;
}
.modal-body .rightSideArea {
  margin-left: 0px;
  padding: 0px;
}

input.text-center  {

  @media (orientation: portrait) and (max-width: 768px) {
    font-size: 20px !important;
    font-family: 'Oswald-Regular';
    width: 100% !important;
    text-overflow: ellipsis;
  }
  
}

img {
  @media (orientation: portrait) and (max-width: 768px) {
    max-width: 100% !important;
  }
}

.fixedLeft {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  padding-right: 15px;
  @media (orientation: portrait) and (max-width: 768px) {
    padding-right: 0px;
  }
}
.sideScroll {
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  height: 100%;
}

.sideScroll.clone {
  height: calc(100vh - 155px);
}

.scrollstyle::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.scrollstyle::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.scrollstyle::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
}

.chapterRow {
  min-height: 70vh !important;
}

.modal_space {
  padding: 1rem;
}

@media (max-width: 1200px) {
  .btn_alone_wrap .col-md-10 {
    flex: 0 0 70%;
    max-width: 70%;
  }

  .btn_alone_wrap .col-md-2 {
    flex: 0 0 30%;
    max-width: 30%;
  }
}
