@import '../styles/variables.scss';

input[type='text'],
textarea {
  &.material {
    border: none;
    border-bottom: 2px solid #bebebe;
    border-radius: 0;
    outline: none;
    transition: all 300ms linear;
    padding: 10px 20px;
    background: #f9f9fa;

    height: auto;
    &:focus {
      outline: none;
      box-shadow: none;
      border-bottom-color: $primary-color;
    }
  }
}
